import React from "react"
import { Link, graphql } from "gatsby"
import { kebabCase } from 'lodash'

import Layout from "../components/layout"
import SEO from "../components/seo"

const gravatar = require('gravatar')

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = pageContext
  const contents = post.frontmatter.contents || []
  const author = post.frontmatter.author
  const email = post.frontmatter.email
  const twitter = post.frontmatter.twitter
  const meta = []
  const cover = post.frontmatter.cover || null
  if (cover) {
    meta.push({
      name: 'twitter:image',
      content: cover,
    })
    meta.push({
      name: 'og:image',
      content: cover,
    })
    meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        meta={meta}
      />

      <header className="pattern-bg p-12 text-white shadow">
        <div className="container mx-auto px-4">
          <h1 className="mb-1 text-shadow">{post.frontmatter.title}</h1>
          <div className="flex items-center">
            { email && <span className="mr-2"><img src={gravatar.url(email)} alt='profile' className="w-8 rounded-full" /></span>}
            { author && <span className="text-white text-xs font-bold mr-3">{author}</span>}
            { twitter && <a target="_blank" href={`https://twitter.com/${twitter}`} className="text-gray-200 text-xs font-bold mr-4 underline">@{twitter}</a>}
            <span className="text-white text-xs italic opacity-75">{post.frontmatter.date}</span>
          </div>
        </div>
      </header>

      <div className="flex flex-wrap container mx-auto px-4">
        <article className="w-full lg:w-3/5 xl:w-4/5 bg-white px-10 py-8">
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            className="text-lg leading-9"
          />
        </article>

        <div className="pt-8 ml-8">
          { contents.length > 0 && 
            <div className="hidden lg:block mb-8">
              <h6 className="mb-2">Contents</h6>
              <ul>
                { contents.map((c, i) => (
                  <li key={i} className="mb-1">
                    <a href={'#' + kebabCase(c)} className="underline text-gray-700">{c}</a>
                  </li>
                )) }
              </ul>
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        contents
        author
        email
        twitter
        cover
      }
    }
  }
`
